<template>
  <div class="temporadas">
    <div v-if="$root.$isAdmin" style="width:100%;text-align:center;margin:10px;">
      <v-btn ripple color="primary" dark :href="'#/capitulo-editor/new'">
        <v-icon color="white">mdi-plus</v-icon>Agregar Capítulo
      </v-btn>
    </div>
    <v-expansion-panels>
    <v-expansion-panel v-for="(temp,t) in temporadas" :key="t" @change="onOpenOrClose(temp)" style="margin-bottom:5px;"> 
      <v-expansion-panel-header class="headline black--text">{{temp.descripcion}} ({{temp.anio}})</v-expansion-panel-header>
      <v-expansion-panel-content class="panel-temporada-content">
          <v-progress-linear class="progress-gradient" mx-auto indeterminate striped v-if="temp.capitulos==null || temp.cargada==false"></v-progress-linear>
          <v-list v-if="temp.capitulos!=null && temp.cargada==true"> 
            <template v-for="(capitulo,c) in temp.capitulos" >
              <v-divider :key="'di_' + c" style="border-color:rgba(50,50,50,0.2)!important"></v-divider>
              <v-list-item :key="'it_' + c"> 
                  <v-list-item-content :style="getItemStyle()" v-on:click="irACapitulo(capitulo)">
                    <v-list-item-title v-html="capitulo.numero + ' - ' + capitulo.nombre"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-speed-dial v-model="fab[capitulo.numero]" style="right:0px!important;" :direction="'left'" :transition="'slide-x-reverse-transition'">
                      <template v-slot:activator >
                        <v-btn v-model="fab[capitulo.numero]" style="background-color:#DD3333;" dark fab small>
                          <v-icon v-if="fab[capitulo.numero]">mdi-close</v-icon>
                          <v-icon v-else>mdi-headphones</v-icon>
                        </v-btn>
                      </template>

                      <v-btn class="fab-link-capitulo" v-if="capitulo.linkSpotify!=null" fab dark small style="background-color:#1dd15d!important" :href="capitulo.linkSpotify" target="_blank">
                        <img :src="require('../assets/logo_spotify_sml.png')"  /> 
                      </v-btn>
                      <v-btn class="fab-link-capitulo" v-if="capitulo.linkIvoox!=null" fab color="orange" dark small :href="capitulo.linkIvoox" target="_blank"> 
                        <img :src="require('../assets/logo_ivoox_sml.png')"/> 
                      </v-btn>
                      <v-btn class="fab-link-capitulo" v-if="capitulo.linkMixcloud!=null" fab color="black" dark small :href="capitulo.linkMixcloud" target="_blank"> 
                        <img :src="require('../assets/logo_mixcloud_sml.png')"/> 
                      </v-btn>
                      <v-btn class="fab-link-capitulo" fab color="blue darken-4" dark small :href="capitulo.linkDescargar" target="_blank"> 
                        <v-icon color="white">mdi-download</v-icon>
                      </v-btn>
                      <v-btn class="fab-link-capitulo" style="width:70px" fab color="red darken-1" dark small v-on:click="irACapitulo(capitulo)"> 
                        <!-- <img :src="require('../assets/logo_bso_btn_sml.png')"/>  -->
                        VER MÁS
                      </v-btn>
                      <v-btn v-if="$root.$isAdmin" class="fab-link-capitulo" fab color="blue" small dark :href="'#/capitulo-editor/' + getNumeroCapitulo(capitulo)">
                        <v-icon color="white">mdi-pencil</v-icon>
                      </v-btn>
                    </v-speed-dial>
                  </v-list-item-action>
              </v-list-item>
            </template>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: "temporadas",
  components: {
  },
  data() {
    return {
      temporadas:[],
      fab:[],
      snackbar:false,
      textoError: null
    };
  },
  created() {
    this.$http.get(this.$root.$basepath + "api/temporadas.php").then(result => {
        result.json().then(elem =>{
          this.temporadas = elem;
          for(let i=0;i<this.temporadas.length;i++){
            this.$set(this.temporadas[i], 'cargada', false);
          }
        });
        }, error => {
          this.mostrarError(error);
    });
	},
  methods: {
    irACapitulo:function(capitulo){
      this.$router.push('/capitulo/' + this.getNumeroCapitulo(capitulo)) ;
    },
    getNumeroCapitulo(capitulo){
      //SI ES SPINOFF, PONGO NEGATIVO
      return (capitulo.temporada==0)?capitulo.numero*-1:capitulo.numero;
    },
    onOpenOrClose(temporada){
      if (temporada.capitulos!=null){return;}
      this.$http.get(this.$root.$basepath +"api/temporadas.php?t=" + temporada.numero).then(result => {
          result.json().then(elem =>{
            temporada.capitulos = elem;
          });
      }, error => {
          this.mostrarError(error);
      });
      setTimeout(this.lateLoad,500,temporada);
    },
    lateLoad(temporada){
      temporada.cargada=true;
      // this.$set(this.temporadas[i], 'cargada', false);
    },
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    },
    getItemStyle(){
      if (this.$vuetify.breakpoint.mdAndUp){
        return 'padding-left:25px;';
      }
      else{
        return 'padding-left:5px;';
      }
    }
  }
};
</script>
<style>
.panel-temporada-content .v-expansion-panel__body {
	border-top: 1px solid #ccc !important;
}
.panel-temporada-content .v-expansion-panel-content__wrap{
  padding-left:5px;
  padding-right:5px;
}
.fab-link-capitulo{
	margin-right:5px!important;
	border-radius:2px!important;
}
.progress-gradient .short,.progress-gradient .long{
  background: linear-gradient(157deg, rgba(255,63,51,1) 0%, rgba(255,103,38,1) 65%, rgba(255,210,0,1) 100%) !important;
}
</style>