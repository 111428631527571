<template>
	<v-hover>
		<template v-slot="{ hover }">
			<v-card :elevation="hover ? 12 : 3" style="border-radius:20px;transition:0.3s linear;cursor:pointer" width="220px" class="mt-3 mb-3" @click="irAPagina(link)" >
				<v-img :src="imagen" class="white--text align-start" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="150px">
					<v-card-title v-if="titulo!=null" class="top-cards-title">{{titulo}}</v-card-title>
				</v-img>
				<v-sheet class="text-center bottom-sheet noselect" :class="classSheet" >
					<v-card-title v-if="tituloInferior!=null" class="bottom-card-title white--text" style="padding-top:2px;padding-bottom:0px">{{tituloInferior}}</v-card-title>
					<div :style="styleTexto" selec style="color:white;height:100%" v-html="texto"></div>
				</v-sheet>
			</v-card>
		</template>
	</v-hover>
</template>

<script>
export default {
	data () {
		return {}
	},
	props: ['titulo','tituloInferior','imagen','link','texto','styleTexto','classSheet'],
	methods:{
		irAPagina: function(link){
			if (link!=null && link!=''){
				this.$router.push({ path: link });
			}
		}
	}
};
</script>
<style>
.bottom-sheet{
	cursor:pointer;
	background-color:#f44336!important;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	height: 60px;
}
.bottom-sheet:hover{
	background-color:#f55b50!important;
}
.bottom-sheet-light{
	cursor:not-allowed;
	/*background-color:#ff6f65!important;*/
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	height: 60px;
	animation-name: to-lighter;
	animation-duration: 500ms;
	animation-fill-mode: forwards;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.bottom-card-title{
	display: block;
    text-align: center;
    font-size: 26px;
    padding-left: 5px!important;
    padding-right: 5px!important;
}

@keyframes to-lighter {
  from {background-color:#f44336;}
  to {background-color:#ff6f65;}
}
</style>