<template>
  <div class="home">
    <v-layout row style="margin:0px!important">
      <v-flex xs12 style="background-color:#EEEEEE" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="title ml-8 mt-6 ">¡No te pierdas los especiales de BSO!</div>
        <v-slide-group class="pa-4 pt-0 especiales-slider"  >
          <v-slide-item v-for="espe in especiales" :key="espe.titulo" class="ml-2 mr-3" transition="slide-x-transition">
            <secciones-elem :tituloInferior="espe.titulo" :imagen="$root.$basepath + 'api/thumbnail.php?t=300&i=rand&se=' + espe.seccion + getWebp()" 
              :texto="espe.seccion==especial?'ESTAS VIENDO':'VER MÁS'" :link="espe.seccion==especial?'':espe.link" 
              :classSheet="espe.seccion==especial?'bottom-sheet-light':''" styleTexto="padding-top:0px;font-size: 0.95em;" />
          </v-slide-item>
        </v-slide-group>
      </v-flex>
      <v-flex xs12>
        <div>
          <div class="headline ma-3" v-if="especial!=null">Especiales: {{especial.replace("_"," ")}}</div>
        </div>
        <div v-if="posts.length==0">
          <cargando-elem class="mb-2" :tag="true" :lineas="6" fecha="true" v-for="skel in 10" :key="'skl-' + skel" />
        </div>
        <post-grande v-for="(post,i) in posts" :key="i" :post="post" />
        <div class="text-center" v-if="posts.length>0">
          <v-pagination v-model="pagina" :length="totalPaginas"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import CargandoElem from '../components/CargandoElem'
import SeccionesElem from '../components/SeccionesElem'
import PostGrande from '../components/PostGrande'

export default {
  name: "especiales",
  components: {
    CargandoElem,
    SeccionesElem,
    PostGrande
  },
  data() {
    return {
      posts: [],
      pagina: 0,
      totalPaginas: 0,
      tags: {
        CAPITULO:{color:'green',icono:'mdi-headphones',texto:'PROGRAMA'},
      },
      especiales: [
        {titulo:"AUTORES", seccion:"AUTORES", link:"/especiales/AUTORES/1"},
        {titulo:"AL OESTE", seccion:"AL_OESTE", link:"/especiales/AL_OESTE/1"},
        {titulo:"NOIR", seccion:"NOIR", link:"/especiales/NOIR/1"},
        {titulo:"SCI-FI", seccion:"SCI-FI", link:"/especiales/SCI-FI/1"},
      ],
      snackbar:false,
      textoError: null,
      buscando:false
    };
  },
  created() {
    let _this=this;
    this.cargarParametros(this.$route.params);
	},
  methods: {
    cargarParametros: function(params){
      this.totalPaginas=0;
      let pag=params.pagina;
      pag = (pag!=null?parseInt(pag):1);
      this.especial=params.especial; 
      if (this.pagina==pag){
        this.llamarBusqueda()
      }
      else{
        this.pagina=pag;
      }
    },
    getPagina: function(){
      this.buscando=false;
      let urlBusqueda = "";
      window.history.pushState(null,'', '#/especiales/' + this.especial + '/' + this.pagina);
      urlBusqueda = "api/especiales.php?p=" + this.pagina + "&tp=" + this.totalPaginas + "&e=" + this.especial;
			this.$http.get(this.$root.$basepath + urlBusqueda).then(result => {
            result.json().then(res=>{
              for(let i=0;i<res.posts.length;i++){
                res.posts[i].tag = this.tags[res.posts[i].tipo];
              }
              this.posts = res.posts;
              if (res.paginas!=null){
                this.totalPaginas = res.paginas; 
              }
          });
          }, error => {
            this.mostrarError(error);
          });
		},
    getWebp: function(){
      return (this.$modernizr.webp?"&webp=1":"");
    },
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    },
    llamarBusqueda: function(){
      this.buscando= true;
      this.posts = new Array();
      let _this = this;
      this.$nextTick().then(function () {
        _this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        _this.getPagina();
      });
    }
  },
  watch:{
		pagina: function(){
      if (!this.buscando){
        this.llamarBusqueda()
      }
    },
    $route(to,from){
      this.cargarParametros(to.params);
    }
	}
};
</script>
<style>
.top-cards-title{
  display: block;
  text-align: center;
  font-size: 30px;
}
.especiales-slider .v-slide-group__content{
  justify-content: center;
}
</style>