<template>
	<v-container fluid >
		<v-layout row wrap class="pl-2 pr-2 pt-2">
			<v-flex style="margin-bottom:20px" class="elevation-6" v-for="(pub,i) in publicidades" :key="i" xs12>
				<a :href="pub.link" target="_blank">
					<video v-if="mostrarVideo(pub)" :src="getVideoUrl(pub)" class="video-style" autoplay loop muted ></video>
					<v-img v-else :src="getImagenUrl(pub)" :style="getStyle(pub)"  >
						<template v-slot:placeholder>
							<v-row class="fill-height ma-0" align="center" justify="center">
								<v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
							</v-row>
						</template>
					</v-img>
				</a>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
  data () {
     return { publicidades:[
			{imagen:'imagenes/saludos/cafecito.webp',link:'http://cafecito.app/bsoradio'},
			{imagen:'imagenes/saludos/corte.webp',link:'https://www.instagram.com/cortoscorte/'},
			{imagen:'imagenes/saludos/tallercine.webp',link:'https://www.facebook.com/melina.cherro'},
			{imagen:'imagenes/saludos/asalallena.webp',link:'http://www.asalallena.com.ar/'},
			{imagen:'imagenes/saludos/asalallena-cursos.webp',link:'http://www.asalallena.com.ar/category/actividades-asl/cursos/'},
			{imagen:'imagenes/saludos/lahoradelmiedo.webp',link:'http://lahoradelmiedo.com/'},
			{imagen:'imagenes/saludos/dcimagen.webp',link:'https://diegocirulo.wixsite.com/dcimagenfotosyetc'},
			{imagen:'imagenes/saludos/cirulov.webp',link:'https://www.instagram.com/cirulov_art/?hl=es'}
		]}
  },
  methods:{
		getStyle:function (pub){
			if (pub.back!=null){
				return 'background-color:' + pub.back; 
			}
			return '';
		},
		mostrarVideo: function(pub){
			return (pub.animado!=null && pub.animado===true && this.$modernizr.video && (this.$modernizr.video.h264 || this.$modernizr.video.webm));
		},
		getImagenUrl: function(pub){
			if (this.$modernizr.webp==false){
				return pub.imagen.replace('.webp','.jpg');
			}
			return pub.imagen;
		},
		getVideoUrl: function(pub){
			if (this.$modernizr.video.webm){
				return pub.imagen.replace('.gif','.webm');
			}
			else if (this.$modernizr.video.h264) {
				return pub.imagen.replace('.gif','.mp4');
			}
		}
	}	
};
</script>
<style>
.video-style{
	display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: auto;
}
</style>