<template>
  <div class="home">
    <v-layout row style="margin:0px!important">
      <v-flex xs12>
        <div v-if="posts.length==0">
          <cargando-elem class="mb-2 ma-1" :tag="true" :lineas="6" fecha="true" v-for="skel in 10" :key="'skl-' + skel" />
        </div>
        <div v-if="posts.length>0">
        <div class="headline ma-3" v-if="busqueda!=null">Resultado de busqueda: {{busqueda}}</div>
        </div>
        <post-grande v-for="(post,i) in posts" :key="i" :post="post" />
        <div class="text-center" v-if="posts.length>0">
          <v-pagination v-model="pagina" :length="totalPaginas"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import CargandoElem from '../components/CargandoElem'
import PostGrande from '../components/PostGrande'

export default {
  name: "busqueda",
  components: {
    CargandoElem,
    PostGrande
  },
  data() {
    return {
      posts: [],
      pagina: 0,
      totalPaginas: 0,
      tags: {
        CAPITULO:{color:'green',icono:'mdi-headphones',texto:'PROGRAMA',borderColor:'#4CAF50'},
        TEXTO:{color:'purple',icono:'mdi-lead-pencil',texto:'BSO-ESCRIBE',borderColor:'#9C27B0'},
        ENTREVISTA:{color:'orange',icono:'mdi-microphone',texto:'BSO-ESCUCHA',borderColor:'#FF9800'}
      },
      snackbar:false,
      textoError: null,
      busqueda:null,
      buscando:false
    };
  },
  created() {
    let _this=this;
    this.$root.$on('busquedaEvent',function(busq,pag){
      _this.cargarParametros({busqueda:busq,pagina:pag});
    });
    this.cargarParametros(this.$route.params);
	},
  methods: {
    cargarParametros: function(params){
      this.totalPaginas=0;
      let pag=params.pagina;
      this.busqueda=params.busqueda; 
      this.especial=params.especial; 
      this.pagina=(pag!=null?parseInt(pag):1);
    },
    getPagina: function(){
      this.buscando=false;
      let urlBusqueda = "";
      window.history.pushState(null,'', '#/busqueda/' + this.busqueda + '/' + this.pagina);
      urlBusqueda = "api/busqueda.php?p=" + this.pagina + "&tp=" + this.totalPaginas + "&b=" + this.busqueda;
			this.$http.get(this.$root.$basepath + urlBusqueda).then(result => {
            result.json().then(res=>{
              for(let i=0;i<res.posts.length;i++){
                res.posts[i].tag = this.tags[res.posts[i].tipo];
              }
              this.posts = res.posts;
              if (res.paginas!=null){
                this.totalPaginas = res.paginas; 
              }
          });
          }, error => {
            this.mostrarError(error);
          });
		},
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    },
    llamarBusqueda: function(){
      this.buscando= true;
      this.posts = new Array();
      let _this = this;
      this.$nextTick().then(function () {
        _this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        _this.getPagina();
      });
    }
  },
  watch:{
		pagina: function(){
      if (!this.buscando){
        this.llamarBusqueda()
      }
    },
    busqueda: function(){
      if (!this.buscando){
        this.totalPaginas=0;
        if (this.pagina==1){
          this.llamarBusqueda()
        }
        else{
          this.pagina=1;
        }
      }
    }
	}
};
</script>
<style>
</style>