<template>
  <div class="home">
    <v-layout row style="margin:0px!important">
      <v-flex xs12 v-if="$vuetify.breakpoint.mdAndUp">
        <v-layout xs12 style="flex-direction:row;background-color:#f44336;padding:5px">
          <v-flex xs6 style="align-items: center;">
            <v-layout xs12 style="flex-direction:row;align-items: center;justify-content: flex-end;margin-right:10%">
              <div v-if="$vuetify.breakpoint.mdAndUp" style="font-family:Roboto, sans-serif;font-size:16px;font-weight:500; color:white;display: inline-block;">¡ESCUCHANOS!</div>
              <bar-icon-link color='white' imagen="spotify_link_bw.png" nombre="Spotify" link="https://open.spotify.com/show/14pzdz8zdRHMeinocZwbIq?si=ou4qkxTPQP-wFMbRhQvx4A" />
              <bar-icon-link color='white' imagen="itunes_link_bw.png" nombre="Itunes" link="https://podcasts.apple.com/ar/podcast/bso-banda-sonora-original/id1397108131" />
              <bar-icon-link color='white' imagen="ivoox_link_bw.png" nombre="Ivoox" link="http://www.ivoox.com/escuchar-bso-banda-sonora-original_nq_91917_1.html" />
              <bar-icon-link color='white' imagen="mixcloud_link.png" nombre="Mixcloud" link="https://www.mixcloud.com/bsoradio/" />
              <bar-icon-link color='white' imagen="youtube_link_bw.png" nombre="YouTube" link="https://www.youtube.com/channel/UCAwA_hNlgUomg0wAZa18DFA" />
            </v-layout>
          </v-flex>
          <v-flex xs6>
            <v-layout xs12 style="flex-direction:row;align-items: center;justify-content: flex-start;margin-left:10%">
              <div v-if="$vuetify.breakpoint.mdAndUp" style="font-family:Roboto, sans-serif;font-size:16px;font-weight:500; color:white;display: inline-block;">¡SEGUINOS!</div>
              <bar-icon-link color='white' imagen="facebook_link_bw.png" nombre="Facebook" link="https://www.facebook.com/bsoradio/" />
              <bar-icon-link color='white' imagen="instagram_link_bw.png" nombre="Instagram" link="https://www.instagram.com/bsoradio/" />
              <bar-icon-link color='white' imagen="twitter_link_bw.png" nombre="Twitter" link="https://twitter.com/BSOradio" />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 style="background-color:#DDDDDD" v-if="$vuetify.breakpoint.mdAndUp">
        <v-layout xs12 style="flex-direction:row;align-items: center;justify-content: center;">
          <secciones-elem class="ml-2 mr-3" titulo="BSO PODCAST" :imagen="$root.$basepath + 'api/thumbnail.php?t=300&ty=ca&i=rand' + getWebp()"
              texto="Escuchá<br/>los capitulos" link="/temporadas" styleTexto="padding-top:8px;line-height:1.3em"/>
          <secciones-elem class="ml-2 mr-3" titulo="BSO ESCRIBE" :imagen="$root.$basepath + 'api/thumbnail.php?t=300&ty=te&i=rand' + getWebp()"
              texto="Leé<br/>los textos" link="/bso-escribe/1" styleTexto="padding-top:8px;line-height:1.3em"/>
          <secciones-elem class="ml-2 mr-3" titulo="BSO ESCUCHA" :imagen="$root.$basepath + 'api/thumbnail.php?t=300&ty=en&i=rand' + getWebp()"
              texto="Escuchá<br/>las entrevistas" link="/bso-escucha/1" styleTexto="padding-top:8px;line-height:1.3em" />
          <secciones-elem class="ml-2 mr-3" titulo="ILUSTRES" :imagen="$root.$basepath + 'api/thumbnail.php?t=300&ty=il&i=rand' + getWebp()"
              texto="Mirá<br/>nuestra miniserie" link="/ilustres/1" styleTexto="padding-top:8px;line-height:1.3em" />
        </v-layout>
      </v-flex>
      <v-flex xs12 style="background-color:#EEEEEE" v-if="$vuetify.breakpoint.mdAndUp">
        <div class="title mt-6 text-center">¡No te pierdas los especiales de BSO!</div>
        <v-slide-group class="pa-4 pt-0 especiales-slider"  >
          <v-slide-item v-for="especial in especiales" :key="especial.titulo" class="ml-2 mr-3" transition="slide-x-transition">
            <secciones-elem :tituloInferior="especial.titulo" :imagen="$root.$basepath + 'api/thumbnail.php?t=300&i=rand&se=' + especial.seccion + getWebp()" 
              texto="VER MÁS" :link="especial.link" styleTexto="padding-top:0px;font-size: 0.95em;"  />
          </v-slide-item>
        </v-slide-group>
      </v-flex>
      <a v-if="$vuetify.breakpoint.smAndDown" class="flex fondo-cafecito pa-3 ma-3 rounded-lg" href="http://cafecito.app/bsoradio" target="blank">
        <div style="display:inline-block;color:white;font-size:20px"><v-icon style="color:white">mdi-coffee</v-icon>¿Nos invitas un cafecito?</div>
        <div style="position:absolute;top:25px;right:15px;"><v-icon>mdi-chevron-right</v-icon><v-icon style="margin-left:-15px">mdi-chevron-right</v-icon></div>
        <div style="color:white;font-size:14px">Ayudanos a seguir creciendo con tu aporte</div>
      </a>
      <v-flex xs12 md9 lg10>
        <div v-if="posts.length==0">
          <cargando-elem class="mb-2" :tag="true" :lineas="6" fecha="true" v-for="skel in 10" :key="'skl-' + skel" />
        </div>
        <post-inicio v-for="(post,i) in posts" :key="i" :post="post" />
        <div class="text-center" v-if="posts.length>0">
          <v-pagination v-model="pagina" :length="totalPaginas"></v-pagination>
        </div>
      </v-flex>
      <v-flex xs0 md3 lg2 v-if="$vuetify.breakpoint.mdAndUp">
        <v-card class="inicio-panel-derecha" color="teal">
          <publicidad-component></publicidad-component>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import PublicidadComponent from '../components/PublicidadComponent'
import CargandoElem from '../components/CargandoElem'
import BarIconLink from '../components/BarIconLink'
import SeccionesElem from '../components/SeccionesElem'
import PostInicio from '../components/PostInicio'

export default {
  name: "inicio",
  components: {
    PublicidadComponent,
    CargandoElem,
    BarIconLink,
    SeccionesElem,
    PostInicio
  },
  data() {
    return {
      posts: [],
      pagina: 0,
      totalPaginas: 0,
      tags: {
        CAPITULO:{color:'green',icono:'mdi-headphones',texto:'PROGRAMA',borderColor:'#4CAF50'},
        TEXTO:{color:'purple',icono:'mdi-lead-pencil',texto:'BSO-ESCRIBE',borderColor:'#9C27B0'},
        ENTREVISTA:{color:'orange',icono:'mdi-microphone',texto:'BSO-ESCUCHA',borderColor:'#FF9800'},
        ILUSTRE:{color:'purple lighten-2',icono:'mdi-brush',texto:'ILUSTRES',borderColor:'#FF9800'}
      },
      especiales: [
        {titulo:"AUTORES", seccion:"AUTORES", link:"/especiales/AUTORES/1"},
        {titulo:"AL OESTE", seccion:"AL_OESTE", link:"/especiales/AL_OESTE/1"},
        {titulo:"NOIR", seccion:"NOIR", link:"/especiales/NOIR/1"},
        {titulo:"SCI-FI", seccion:"SCI-FI", link:"/especiales/SCI-FI/1"},
      ],
      snackbar:false,
      textoError: null
    };
  },
  created() {
		let pag=this.$route.params.pagina;
		if (pag!=null){
			this.pagina=parseInt(pag);
		}
		else{
			this.pagina=1;
		}
	},
  methods: {
    getPagina: function(){
			window.history.pushState(null,'', '#/inicio/' + this.pagina);
			this.$http.get(this.$root.$basepath + "api/inicio.php?p=" + this.pagina + "&tp=" + this.totalPaginas).then(result => {
            result.json().then(res=>{
              for(let i=0;i<res.posts.length;i++){
                res.posts[i].tag = this.tags[res.posts[i].tipo];
              }
              this.posts = res.posts;
              if (res.paginas!=null){
                this.totalPaginas = res.paginas; 
              }
          });
          }, error => {
            this.mostrarError(error);
          });
		},
    getWebp: function(){
      return (this.$modernizr.webp?"&webp=1":"");
    },
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    }
  },
  watch:{
		pagina: function(){
      this.posts = new Array();
      let _this = this;
      this.$nextTick().then(function () {
        _this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        _this.getPagina();
      });
		}
	}
};
</script>
<style>
.top-cards-title{
  display: block;
  text-align: center;
  font-size: 30px;
  padding-left: 5px!important;
  padding-right: 5px!important;
}
.especiales-slider .v-slide-group__content{
  justify-content: center;
}
.fondo-cafecito{
  background-image: url(../assets/fondo-1-new.png);
  background-position: 0px 0px;
  background-repeat: repeat-y;
  background-size: 100%;
  z-index: 1;
}
</style>