<template>
  <div class="capitulo">
    <v-layout row style="margin:0px!important">
      <v-flex xs12 md9>
        <v-flex v-if="capitulo==null" class="justify-center" style="text-align:center;margin-top:40px">
          <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
        </v-flex>
        <v-card v-if="capitulo!=null" class="pb-5">
          <v-img v-if="capitulo.imagen!=null" :src="$root.$basepath + 'imagenes/' + capitulo.imagen" :height="getImageHeight()" class="grey lighten-2" :position="capitulo.posicionImagen">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title primary-title>
            <div style="width:100%">
              <h3 class="headline" style="margin-bottom: 20px!important;">{{capitulo.nombre}}</h3>
              <div class="body-2 pl-1 mb-3" style="margin-top: -20px;">{{getFecha(capitulo)}}</div>
              <iframe v-if="capitulo.linkMixcloud!=null" width="100%" height="60" :src="getSrcMixcloud(capitulo.linkMixcloud)" frameborder="0" ></iframe>
              <iframe v-if="capitulo.linkIvoox!=null && capitulo.linkMixcloud==null" width="100%" height="200" frameborder="0" allowfullscreen="" scrolling="no" loading="lazy" :src="getSrcIvoox(capitulo.linkIvoox)"></iframe>
              <div v-html="capitulo.texto" class="body-1" style="text-align:justify;word-break: normal!important;"></div>
            </div>
          </v-card-title>
          <v-card-actions :style="getLinksStyle()">
            <v-btn class="btn-link-capitulo" v-if="capitulo.linkSpotify!=null" style="background-color:#1dd15d!important" dark :href="capitulo.linkSpotify" target="_blank">
              <img :src="require('../assets/logo_spotify_sml.png')" style="padding-right:5px" />{{getBtnPrefix() + 'Spotify'}} 
            </v-btn>
            <v-btn class="btn-link-capitulo" v-if="capitulo.linkIvoox!=null" color="orange" dark :href="capitulo.linkIvoox" target="_blank"> 
              <img :src="require('../assets/logo_ivoox_sml.png')" style="padding-right:5px" />{{getBtnPrefix() + 'Ivoox'}}
            </v-btn>
            <v-btn class="btn-link-capitulo" color="blue darken-4" dark :href="capitulo.linkDescargar" target="_blank"> 
              <img :src="require('../assets/logo_mediafire_sml.png')" style="padding-right:5px" />Descargar
            </v-btn>
            <v-btn class="btn-link-capitulo" v-if="$root.$isAdmin" color="blue darken-4" dark :href="'#/capitulo-editor/' + capitulo.numero"> 
              <v-icon>mdi-pen</v-icon>Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex class="hidden-sm-and-down" md3>
        <h3 style="text-align:center;padding:10px;">También te puede interesar</h3>
        <v-flex v-if="relacionados==null" class="justify-center" style="text-align:center">
          <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
        </v-flex>
        <v-flex v-if="relacionados!=null">
        <v-card v-for="(rel,i) in relacionados" :key="i" style="margin:10px" class="elevation-8" :href="'/capitulo/' + rel.numero" >
          <v-img v-if="rel.imagen!=null" :src="getImageUrl(rel)" height="200px" class="white--text align-end">
            <v-card-title primary-title class="pl-3 pr-3 pt-0 pb-1 txt-relacionado">
              <div>
                <h3 class="title mb-0" style="font-size:16px!important">{{rel.nombre}}</h3>
                <div class="body-2 pl-1" style="margin-top: -5px;">{{getFecha(rel)}}</div>
              </div>
            </v-card-title>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: "capitulo",
  components: {
  },
  data() {
    return {
      capitulo: null,
      relacionados: null,
      snackbar:false,
      textoError: null
    };
  },
  created() {
    const idCapitulo=this.$route.params.id;
    this.$http.get(this.$root.$basepath + "api/capitulos.php?c=" + idCapitulo).then(result => {
        result.json().then(capitulo =>{
          this.capitulo = capitulo;
        });
    }, error => {
        this.mostrarError(error);
    });
    this.$http.get(this.$root.$basepath + "api/capitulos.php?r=" + idCapitulo).then(result => {
      result.json().then(relacionados =>{
        this.relacionados = relacionados;
      });
    }, error => {
      this.mostrarError(error);
    });
  },
  mounted(){
    this.$vuetify.goTo(0, {duration: 500,easing: 'linear'});
  },
  methods: {
    getImageHeight: function (){
      if (this.$vuetify.breakpoint.lgAndUp){
				return '500px';
      }
      else{
        return '400px';
      }
    },
    getLinksStyle: function(){
			if (this.$vuetify.breakpoint.xs){
				return 'flex-wrap:wrap;justify-content: space-evenly';
			}
			return 'flex-wrap:wrap;justify-content: space-between';
		},
		getBtnPrefix: function(){
			if (this.$vuetify.breakpoint.xs){
				return '';
			}
			return 'Escuchar en '; 
		},
		getSrcMixcloud: function(link){
			return 'https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&feed=' + link.replace('https://www.mixcloud.com','');
		},
		getSrcIvoox: function(link){
			return link.replace('https://ar.ivoox.com/es/','https://www.ivoox.com/player_ej_') + '_6_1.html?c1=fb9b1c';
      //https://ar.ivoox.com/es/player_ej_80282644_6_1.html?c1=fb9b1c'
      //https://www.ivoox.com/player_ej_114799610_6_1.html?c1=fb9b1c
		},
		getFecha: function (rel){
			return dayjs(rel.fecha).format('DD/MM/YYYY');
    },
    getImageUrl: function(relacionado){
			return this.$root.$basepath + 'api/thumbnail.php?ty=ca&i=' + encodeURIComponent(relacionado.imagen) + this.$root.$webp;
    },
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    }
  }
};
</script>
<style>
.txt-relacionado{
   background:linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,0.3) 20%, rgba(0,0,0,0.5) 100%);
}
.btn-link-capitulo{
  margin-bottom:5px!important;
}
</style>